<dasch-swiss-app-progress-indicator *ngIf="isLoading$ | async"></dasch-swiss-app-progress-indicator>
<div *ngIf="(isLoading$ | async) === false">
  <!-- password update: header -->
  <div class="app-toolbar more-space-bottom">
    <div class="app-toolbar-row">
      <h3 class="mat-body subtitle">{{ 'form.user.title.edit' | translate }}</h3>
      <span class="fill-remaining-space"></span>
      <span class="app-toolbar-action"> </span>
    </div>
    <div class="app-toolbar-row">
      <h2 class="mat-headline-6">{{ 'form.user.title.password' | translate }}</h2>
      <span class="fill-remaining-space"></span>
      <span class="app-toolbar-action"> </span>
    </div>
  </div>
  <!-- password update: form -->
  <app-password-form [user]="user$ | async"></app-password-form>

  <!-- danger zone: delete account -->
  <div class="app-danger-zone more-space-top">
    <!-- delete account: header -->
    <div class="app-toolbar more-space-bottom">
      <div class="app-toolbar-row">
        <h3 class="mat-body subtitle">{{ 'form.action.danger' | translate }}</h3>
        <span class="fill-remaining-space"></span>
        <span class="app-toolbar-action"> </span>
      </div>
      <div class="app-toolbar-row">
        <h2 class="mat-headline-6">
          <span *ngIf="(user$ | async)?.status">{{ 'form.user.title.delete' | translate }}</span>
          <span *ngIf="!((user$ | async)?.status)">Reactivate user</span>
        </h2>
        <span class="fill-remaining-space"></span>
        <span class="app-toolbar-action"> </span>
      </div>
    </div>
    <!-- delete account: button -->
    <div class="center more-space-top more-space-bottom">
      <button *ngIf="(user$ | async)?.status" mat-button [color]="'warn'" (click)="askToDeleteUser(username, userId)">
        {{ 'form.user.title.deleteButton' | translate | uppercase }}
      </button>

      <button
        *ngIf="!((user$ | async)?.status)"
        mat-button
        [color]="'primary'"
        (click)="askToActivateUser(username, userId)">
        ACTIVATE ACCOUNT
      </button>
    </div>
  </div>
</div>
