<div [formGroup]="form">
  <div class="date-form-grid">
    <div class="date-form-field">
      <dasch-swiss-app-date-picker
        class="start-date"
        [formControlName]="'startDate'"
        (closed)="handlePickerClose()"></dasch-swiss-app-date-picker>
    </div>

    <button
      mat-button
      class="toggle-period-control"
      [disabled]="!startDate.value"
      [matTooltip]="
                isPeriodControl.value ? 'Delete end date' : 'Add end date'
            "
      matTooltipPosition="above"
      (click)="togglePeriodControl($event)">
      <mat-icon *ngIf="!isPeriodControl.value">keyboard_tab</mat-icon>
      <mat-icon *ngIf="isPeriodControl.value">remove</mat-icon>
    </button>

    <div *ngIf="isPeriodControl.value && startDate.value" class="date-form-field">
      <dasch-swiss-app-date-picker
        class="end-date"
        [formControlName]="'endDate'"
        [disableCalendarSelector]="true"
        [calendar]="startDate.value.calendar">
      </dasch-swiss-app-date-picker>
    </div>
  </div>

  <div class="date-form-error">
    <mat-error *ngIf="startDate.hasError('required') && pickerClosed">
      <span class="custom-error-message">One date is <strong>required</strong></span>
    </mat-error>
    <mat-error *ngIf="endDate.hasError('required') && pickerClosed">
      <span class="custom-error-message">End date is <strong>required</strong></span>
    </mat-error>
    <mat-error *ngIf="startDate.hasError('periodStartEnd')">
      <span class="custom-error-message">End date must be after start date.</span>
    </mat-error>
  </div>
</div>
