<div class="class-item-container">
  <div class="content" [routerLinkActive]="['is-active']" (click)="selectItem()">
    <div class="box link" [routerLink]="classLink">
      <div
        #resClassLabel
        class="label"
        matTooltip="{{ontologiesLabel}}"
        matTooltipShowDelay="750"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="tooltipDisabled">
        {{ ontologiesLabel }}
      </div>
      <div class="entry-container">
        <mat-icon>{{ icon }}</mat-icon>
        <ngx-skeleton-loader
          *ngIf="(results$ | async) === undefined"
          count="1"
          appearance="line"
          [theme]="{
                        'margin-bottom': 0,
                        'vertical-align': 'middle'
                    }">
        </ngx-skeleton-loader>
        <div *ngIf="(results$ | async) !== undefined" class="entry">
          {{ results$ | async | i18nPlural: itemPluralMapping['entry'] }}
        </div>
      </div>
    </div>
    <a class="icon link" *ngIf="projectMember" (click)="goToAddClassInstance()">
      <mat-icon>add_circle_outline</mat-icon>
    </a>
  </div>
</div>
