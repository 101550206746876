<div *ngIf="(isLoading$ | async) === false" class="data-models-container" data-cy="data-models-container">
  <div class="header">
    <p class="title">All Data Models</p>
    <mat-icon
      color="primary"
      class="icon"
      matTooltip="A data model organizes data elements and specifies how they relate to one another and by which properties they are described."
      matTooltipPosition="above">
      info
    </mat-icon>
  </div>
  <div *ngIf="isAdmin$ | async" class="action-buttons">
    <button color="primary" data-cy="create-button" mat-raised-button class="create" (click)="createNewOntology()">
      <mat-icon class="v-align-middle">add_circle</mat-icon>
      <span class="v-align-middle">Create New</span>
    </button>
    <a
      color="primary"
      mat-stroked-button
      class="docs"
      href="https://docs.dasch.swiss/latest/DSP-APP/user-guide/project/#data-model"
      target="_blank">
      Read Documentation About How To Create Data Models
      <mat-icon>chevron_right</mat-icon>
    </a>
  </div>
  <div *ngIf="ontologiesMetadata$ | async" class="projectOntos">
    <div class="list" [class.top-padding]="isAdmin$ | async">
      <div
        class="list-item ontos"
        *ngFor="let onto of ontologiesMetadata$ | async; trackBy: trackByOntologyMetaFn"
        data-cy="ontology-button"
        matTooltip="You must be logged in to view data models"
        matTooltipPositionAtOrigin="true"
        [matTooltipDisabled]="(isLoggedIn$ | async)"
        (click)="navigateToOntology(onto.id)">
        <mat-icon class="icon-prefix">bubble_chart</mat-icon>
        <p class="label">{{onto.label}}</p>
        <span class="fill-remaining-space"></span>
        <mat-icon class="icon-suffix">chevron_right</mat-icon>
      </div>
    </div>
  </div>
  <div class="header">
    <p class="title">Controlled Vocabularies</p>
    <mat-icon
      color="primary"
      class="icon"
      matTooltip="Controlled vocabularies are hierarchical or non-hierarchical lexica of reference terms. Due to their normative or standardized nature, controlled vocabularies improve data quality and make database searching more efficient than free-text fields."
      matTooltipPosition="above">
      info
    </mat-icon>
  </div>
  <div *ngIf="isAdmin$ | async" class="action-buttons">
    <a color="primary" mat-raised-button class="create" [routerLink]="['..', RouteConstants.addList]">
      <mat-icon class="v-align-middle">add_circle</mat-icon>
      <span class="v-align-middle">Create New</span>
    </a>
  </div>
  <div *ngIf="(listsInProject$ | async)?.length > 0" class="projectLists">
    <div class="list" [class.top-padding]="isAdmin$ | async">
      <div
        class="list-item"
        *ngFor="let list of (listsInProject$ | async); trackBy: trackByFn"
        matTooltip="You must be logged in to view data models"
        matTooltipPositionAtOrigin="true"
        [matTooltipDisabled]="(isLoggedIn$ | async)"
        (click)="navigateToList(list.id)">
        <mat-icon class="icon-prefix">list</mat-icon>
        <p class="label">{{list.labels | appStringifyStringLiteral}}</p>
        <span class="fill-remaining-space"></span>
        <mat-icon class="icon-suffix">chevron_right</mat-icon>
      </div>
    </div>
  </div>
</div>
