<div class="app-toolbar transparent more-space-bottom">
  <div class="app-toolbar-row toolbar-subtitle">
    <h3 class="mat-body subtitle">Increase your team</h3>
  </div>
  <div class="app-toolbar-row toolbar-form">
    <h2 class="mat-headline-6">{{ 'addProjectMember.title' | translate }}</h2>

    <span class="fill-remaining-space"></span>

    <span class="app-toolbar-action select-form">
      <form [formGroup]="selectUserForm" class="form">
        <!-- auto complete list to select user -->
        <div class="form-content">
          <mat-form-field class="large-field select-user">
            <mat-label>{{'addProjectMember.select' | translate}}</mat-label>
            <input matInput [matAutocomplete]="user" [formControl]="selectUserForm.controls['addUser']" />
            <button
              class="reset-button"
              mat-icon-button
              matSuffix
              *ngIf="selectUserForm.controls['addUser'].value"
              (click)="resetInput($event)"
              type="button">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #user="matAutocomplete" (optionSelected)="addUser($event.option.value)">
              <mat-option *ngIf="!users?.length" class="loading-option">
                <div class="loading-container">
                  <mat-spinner diameter="20"></mat-spinner>
                  <span>{{ 'addProjectMember.usersLoading' | translate }}</span>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let user of filteredUsers$ | async; trackBy: trackByFn"
                [value]="user.id"
                [disabled]="isMember(user)">
                {{ getLabel(user) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
    </span>

    <span class="fill-remaining-space"></span>

    <span class="app-toolbar-action select-form">
      <button mat-raised-button color="primary" class="add-new create-user-btn" (click)="createUser()">
        {{ 'addProjectMember.newUser' | translate }}
      </button>
    </span>
  </div>
</div>
