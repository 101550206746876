<div class="content large">
  <button color="primary" mat-button class="previousBtn" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
    Back
  </button>
</div>
<div class="content large middle">
  <h1>Cookie Policy for DSP-API web application</h1>

  <h3>What Are Cookies</h3>

  <p>
    As is common practice with almost all professional websites this site uses cookies, which are tiny files that are
    downloaded to your computer, to improve your experience. This page describes what information they gather, how we
    use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from
    being stored however this may downgrade or 'break' certain elements of the sites functionality.
  </p>

  <p>For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>

  <mat-divider></mat-divider>

  <h3>How We Use Cookies</h3>

  <p>
    We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard
    options for disabling cookies without completely disabling the functionality and features they add to this site. It
    is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used
    to provide a service that you use.
  </p>

  <mat-divider></mat-divider>

  <h3>Disabling Cookies</h3>

  <p>
    You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to
    do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you
    visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site.
    Therefore it is recommended that you do not disable cookies.
  </p>

  <mat-divider></mat-divider>

  <h3>The Cookies We Set</h3>

  <ul>
    <li>
      <p>Account related cookies</p>
      <p>
        If you create an account with us then we will use cookies for the management of the signup process and general
        administration. These cookies will usually be deleted when you log out however in some cases they may remain
        afterwards to remember your site preferences when logged out.
      </p>
    </li>

    <li>
      <p>Login related cookies</p>
      <p>
        We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in
        every single time you visit a new page. These cookies are typically removed or cleared when you log out to
        ensure that you can only access restricted features and areas when logged in.
      </p>
    </li>

    <li>
      <p>Forms related cookies</p>
      <p>
        When you submit data to through a form such as those found on contact pages or comment forms cookies may be set
        to remember your user details for future correspondence.
      </p>
    </li>

    <li>
      <p>Site preferences cookies</p>
      <p>
        In order to provide you with a great experience on this site we provide the functionality to set your
        preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies
        so that this information can be called whenever you interact with a page is affected by your preferences.
      </p>
    </li>
  </ul>

  <mat-divider></mat-divider>

  <h3>Third Party Cookies</h3>

  <p>
    In some special cases we also use cookies provided by trusted third parties. The following section details which
    third party cookies you might encounter through this site.
  </p>

  <ul>
    <li>
      <p>
        From time to time we test new features and make subtle changes to the way that the site is delivered. When we
        are still testing new features these cookies may be used to ensure that you receive a consistent experience
        whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.
      </p>
    </li>
  </ul>

  <mat-divider></mat-divider>

  <h3>More Information</h3>

  <p>
    Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't
    sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the
    features you use on our site.
  </p>
</div>
