<div [formGroup]="dateForm" class="date-picker-container">
  <mat-form-field class="hidden">
    <input matInput [formControlName]="'knoraDate'" [errorStateMatcher]="matcher" readonly />
  </mat-form-field>
  <mat-form-field
    [floatLabel]="'always'"
    class="child-input-component date-picker-value"
    [matMenuTriggerFor]="datePicker">
    <mat-label>{{ titleCase(calendar) }}</mat-label>
    <input
      matInput
      class="date-picker-input date"
      [formControlName]="'date'"
      placeholder="Click to select a date"
      autocomplete="off"
      readonly />
    <mat-icon color="primary" matSuffix class="link">event</mat-icon>
    <!-- <mat-error *ngIf="dateForm.controls.date.hasError('required')">
            Date is <strong>required</strong>
        </mat-error> -->
  </mat-form-field>
</div>

<!-- <mat-form-field class="date" [matMenuTriggerFor]="datePicker">
    <mat-label>{{calendar}} {{era}}</mat-label>
    <input matInput class="link" autocomplete="off" readonly placeholder="dd/mm/yyyy or mm/yyyy or yyyy" [value]="date | knoraDate">
    <mat-icon matSuffix class="link">event</mat-icon>
</mat-form-field> -->
<!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button> -->
<mat-menu #datePicker="matMenu" [formGroup]="form" class="date-picker" (closed)="closed.emit()">
  <div class="date-picker-content" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <!-- select calendar and button to close date picker -->
    <div class="calendar-selector panel">
      <mat-form-field class="calendar">
        <mat-label>Calendar</mat-label>
        <mat-select formControlName="calendar" class="center">
          <mat-option *ngFor="let cal of calendars; let i = index" [value]="cal"> {{ titleCase(cal) }} </mat-option>
        </mat-select>
      </mat-form-field>
      <span class="fill-remaining-space"></span>
      <div class="action">
        <!-- go to the current date -->
        <button mat-button (click)="setToday()">Today</button>
        <!-- submit button (which is needed if date doesn't have precision on an exact day) -->
        <button mat-icon-button color="primary" [disabled]="!form.valid" (click)="setDate(day); closeDatePicker()">
          <mat-icon>done</mat-icon>
        </button>
      </div>
    </div>

    <!-- panel with month (incl. navigation), year and era -->
    <div class="month-year-selector panel">
      <!-- select month -->
      <mat-form-field class="month" [class.larger]="calendar === 'ISLAMIC'">
        <mat-label>Month</mat-label>
        <mat-select formControlName="month" class="center">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let month of months; let i = index" [value]="i + 1">
            <span *ngIf="calendar !== 'ISLAMIC'">{{ month[0] }}</span>
            <span *ngIf="calendar === 'ISLAMIC'">{{ month[1] }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- set year incl. era -->
      <mat-form-field class="year" [class.withButtonToggle]="calendar !== 'ISLAMIC'">
        <mat-label>Year</mat-label>
        <input matInput type="number" formControlName="year" min="1" class="center" />
        <mat-error *ngIf="formErrors.year"> {{ formErrors.year }} </mat-error>
        <!-- <button mat-flat-button matSuffix (click)="switchEra()" [attr.aria-label]="'Switch era'"
                [attr.aria-pressed]="ce">
                {{ce ? 'CE' : 'BCE'}}
            </button> -->
        <mat-button-toggle-group
          *ngIf="calendar !== 'ISLAMIC'"
          class="era suffix-toggle-group"
          matSuffix
          formControlName="era"
          aria-label="Define era">
          <mat-button-toggle value="CE">CE</mat-button-toggle>
          <mat-button-toggle value="BCE">BCE</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-form-field>

      <!-- hidden slide toggle to know about era changed -->
      <!-- <mat-slide-toggle formControlName="era">CE</mat-slide-toggle> -->
    </div>

    <!-- select day -->
    <div class="day-selector panel" [class.disabled]="disableDaySelector">
      <div>
        <p *ngIf="era === 'CE'" class="week-days">
          <span *ngFor="let d of weekDays" class="day">{{ d }}</span>
        </p>
        <p *ngIf="era === 'BCE'" class="mat-caption center">
          <!-- <mat-icon>warn</mat-icon> -->
          Attention: Date <strong>before common era</strong>.
        </p>
      </div>
      <div *ngFor="let week of weeks; let last = last" class="week">
        <span *ngFor="let d of week" class="day" [class.selected]="d === day">
          <!-- the following span is only a placeholder to set the position of the first day to the correct column-->
          <span *ngIf="d === 0"></span>
          <span
            *ngIf="d > 0"
            class="selectable link"
            [class.disabled]="disableDaySelector"
            (click)="
                            !disableDaySelector && setDate(d); closeDatePicker()
                        "
            >{{ d }}</span
          >
        </span>
        <!-- add not interested button at the end, if you want precision on year and month only -->
        <span *ngIf="last && week.length < 7" class="day">
          <span
            class="selectable link"
            [class.disabled]="disableDaySelector"
            (click)="
                            !disableDaySelector && setDate(); closeDatePicker()
                        ">
            <mat-icon>not_interested</mat-icon>
          </span>
        </span>
      </div>
      <!-- add not interested button at the end, if you want precision on year and month only;
                if the month ends on a Sunday, we have to add an additional line to get the not-interested option -->
      <div class="week" *ngIf="weeks[weeks.length - 1].length === 7">
        <span class="day">
          <span
            class="selectable link"
            [class.disabled]="disableDaySelector"
            (click)="
                            !disableDaySelector && setDate(); closeDatePicker()
                        ">
            <mat-icon>not_interested</mat-icon>
          </span>
        </span>
      </div>
    </div>
  </div>
</mat-menu>
