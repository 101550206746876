<app-dialog-header
  [title]="editExistingUser ? userForm.controls.username.value : ('form.user.title.profile' | translate)"
  [subtitle]="editExistingUser ? ('form.user.title.edit' | translate) : ('form.user.title.new' | translate)">
</app-dialog-header>
<form [formGroup]="userForm" class="form-content user-form">
  <app-common-input
    [class]="editExistingUser ? 'small-field' : 'large-field' "
    [control]="userForm.controls.username"
    [validatorErrors]="[usernamePatternErrorMsg]"
    [label]="'form.user.general.username' | translate" />
  <app-common-input
    [class]="editExistingUser ? 'medium-field' : 'large-field' "
    [control]="userForm.controls.email"
    [label]="'form.user.general.email' | translate"
    [validatorErrors]="[emailPatternErrorMsg]" />
  <app-common-input
    class="small-field"
    [control]="userForm.controls.givenName"
    [label]="'form.user.general.givenName' | translate" />
  <app-common-input
    class="medium-field"
    [control]="userForm.controls.familyName"
    [label]="'form.user.general.familyName' | translate" />
  <app-password-form
    *ngIf="!editExistingUser"
    (password)="this.userForm.controls.password.setValue($event)"></app-password-form>
  <mat-form-field class="large-field">
    <mat-label>{{ 'form.user.general.language' | translate }}</mat-label>
    <mat-select [formControl]="userForm.controls.lang">
      <mat-option *ngFor="let lang of languagesList" [value]="lang.language"> {{ lang.value }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="large-field" *ngIf="loggedInUserIsSysAdmin$ | async">
    <mat-slide-toggle
      [checked]="userForm.controls.systemAdmin.value"
      (change)="userForm.controls.systemAdmin.setValue(!userForm.controls.systemAdmin.value)"
      [disabled]="editExistingUser">
      {{ 'form.user.general.sysAdmin' | translate }}
    </mat-slide-toggle>
  </div>
</form>
