<div class="header">Select a list value</div>
<div class="dropdown" [matMenuTriggerFor]="rootMenu">
  <div class="container">
    <div class="label">{{ selectedItem ? selectedItem : 'Select a value' }}</div>
    <div class="icon">
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </div>
  <div class="line"></div>
</div>
<mat-menu #rootMenu="matMenu">
  <span *ngFor="let child of list?.children">
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="menu.childMenu" (click)="onItemClicked(child)" type="button">
        {{ child.label }}
      </button>
      <dasch-swiss-list-item #menu [children]="child.children" (selectedNode)="onItemClicked($event)">
      </dasch-swiss-list-item>
    </span>

    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item (click)="onItemClicked(child)" type="button">{{ child.label }}</button>
    </span>
  </span>
</mat-menu>
