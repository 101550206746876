<dasch-swiss-app-progress-indicator *ngIf="(isProjectsLoading$ | async) === true"></dasch-swiss-app-progress-indicator>
<div *ngIf="(isProjectsLoading$ | async) === false" class="app-projects">
  <!-- <mat-divider></mat-divider> -->
  <app-projects-list
    [list]="activeProjects$ | async"
    [status]="true"
    (refreshParent)="refresh()"
    [createNew]="true"
    [isUsersProjects]="isUsersProjects"
    data-cy="active-projects-section">
  </app-projects-list>
  <!-- already deactivated projects: disable the menu -->
  <app-projects-list
    [list]="inactiveProjects$ | async"
    [status]="false"
    [isUsersProjects]="isUsersProjects"
    (refreshParent)="refresh()"
    data-cy="inactive-projects-section"></app-projects-list>
</div>
