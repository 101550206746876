<div class="add-property-button">
  <button mat-button [disabled]="addButtonDisabled" (click)="onAddPropertyFormClicked()">Add Search Criteria</button>
</div>
<div class="action-buttons">
  <button mat-button class="reset-button" [disabled]="resetButtonDisabled" (click)="onResetButtonClicked()">
    Reset
  </button>
  <button mat-button class="search-button" [disabled]="searchButtonDisabled" (click)="onSearchButtonClicked()">
    Search
  </button>
</div>
