<div class="app-grid">
  <div class="app-grid-item" *ngFor="let item of list">
    <mat-icon *ngIf="item.icon" class="topic">{{ item.icon }}</mat-icon>
    <!-- <h3>Ensures Longevity of Humanities Data</h3> -->
    <h3>{{ item.title }}</h3>
    <p>{{ item.text }}</p>
    <br /><br />
    <p class="action center" *ngIf="item.url">
      <span [ngSwitch]="item.url.substring(0, 4)">
        <!-- in case of http (external address) -->
        <a mat-button *ngSwitchCase="'http'" [href]="item.url" target="_blank" color="primary" class="external-url">
          {{ item.urlText ? item.urlText : 'Read more'}}
          <mat-icon iconPositionEnd class="suffix">launch</mat-icon>
        </a>

        <!-- in case of mailto -->
        <a mat-button *ngSwitchCase="'mail'" [href]="item.url" color="primary" class="mailto">
          {{ item.urlText ? item.urlText : 'Read more'}}
          <mat-icon iconPositionEnd class="suffix">mail</mat-icon>
        </a>

        <!-- in case of internal address -->
        <button
          mat-button
          *ngSwitchDefault
          [routerLink]="[item.url]"
          routerLinkActive="router-link-active"
          color="primary"
          class="internal-url">
          {{ item.urlText ? item.urlText : 'Read more' }}
        </button>
      </span>
    </p>
  </div>
</div>
