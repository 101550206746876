<div class="third-party-iiif-preview">
  <div
    *ngIf="(!previewImageUrl || !iiifUrlControl.valid) && previewStatus === 'IDLE'"
    class="third-party-iiif-preview-placeholder">
    <mat-icon class="iiif-preview-icon">preview</mat-icon>
    <div>[ preview ]</div>
    <div class="mat-subtitle-2 iiif-url-explanation">
      The URL must point to a valid IIIF image (jpg, tif, jp2, png). <br />Example: {{exampleString}}
    </div>
  </div>
  <dasch-swiss-app-progress-indicator *ngIf="previewStatus === 'LOADING'"></dasch-swiss-app-progress-indicator>
  <img
    *ngIf="iiifUrlControl?.valid"
    [src]="previewImageUrl"
    (load)="previewStatus = 'IDLE'"
    alt="IIIF Preview"
    height="240" />
</div>
<div class="mat-subtitle-2 paste-url-explanation">Paste a valid IIIF image URL to preview it here.</div>

<mat-form-field class="third-party-iiif-field">
  <mat-label>IIIF Image URL</mat-label>
  <input matInput [formControl]="iiifUrlControl" placeholder="Enter IIIF image URL" data-cy="external-iiif-input" />
  <mat-error *ngIf="iiifUrlControl.errors as errors"> {{ errors | humanReadableError: validatorErrors }} </mat-error>
  <button
    *ngIf="iiifUrlControl.value"
    [disabled]="iiifUrlControl.valid"
    (click)="resetIfInvalid()"
    mat-icon-button
    matSuffix
    type="button"
    color="primary"
    data-cy="external-iiif-reset">
    <mat-icon>{{ iiifUrlControl.valid ? 'check' : 'close'}}</mat-icon>
  </button>
  <button
    type="button"
    color="primary"
    mat-icon-button
    matSuffix
    matTooltip="Paste from clipboard"
    (click)="pasteFromClipboard()"
    data-cy="external-iiif-paste">
    <mat-icon>content_paste</mat-icon>
  </button>
</mat-form-field>
