<div class="property-info">
  <div class="upper-prop-container">
    <div mat-line class="prop-label">
      <div
        *ngIf="propType"
        [matTooltip]="propType?.group + ': ' + propType?.label + ' (' + propDef.id.split('#')[1] + ')'"
        matTooltipPosition="above">
        <mat-icon class="prop-type-icon">{{ propType?.icon }}</mat-icon>
      </div>
      <div>
        <span
          class="label"
          [matTooltip]="propDef.comment"
          matTooltipPosition="above"
          matTooltipClass="multi-line-tooltip"
          data-cy="property-label"
          >{{ propDef.label ? propDef.label : propDef.id }}
        </span>
        <span
          class="attribute additional-info"
          *ngIf="propAttribute"
          [matTooltip]="propAttributeComment"
          matTooltipPosition="above"
          [innerHTML]="'&rarr;&nbsp;' + propAttribute"></span>
      </div>
    </div>
    <div class="edit-menu">
      <div class="menu-icon">
        <mat-icon
          [matMenuTriggerFor]="classInfoMenu"
          class="menu-icon-button"
          (click)="canBeRemovedFromClass()"
          *ngIf="userCanEdit"
          >more_vert</mat-icon
        >
      </div>
    </div>
  </div>
  <div mat-line class="lower-prop-container">
    <span
      [matTooltip]="'id: ' + propDef.id"
      matTooltipPosition="above"
      matTooltipClass="wide-tooltip"
      class="mat-caption">
      {{ propDef.id | split: '#':1 }}
    </span>
    <span>
      <app-cardinality
        [disabled]="!userCanEdit"
        [cardinality]="propCard.cardinality"
        [classIri]="resourceClass.id"
        [propertyInfo]="{ propDef: this.propDef, propType: this.propType }"
        (cardinalityChange)="submitCardinalitiesChange($event)"></app-cardinality>
    </span>
  </div>
</div>

<mat-menu #classInfoMenu="matMenu">
  <div class="remove-menu-wrapper">
    <button
      mat-menu-item
      [disabled]="!propCanBeRemovedFromClass"
      (click)="removePropertyFromClass.emit({iri: propDef.id, label: propDef.label})">
      <mat-icon>link_off</mat-icon>
      <ngx-skeleton-loader
        *ngIf="propCanBeRemovedFromClass === undefined || propCanBeRemovedFromClass === null"
        count="1"
        appearance="line"
        class="skeleton"
        [theme]="{
                        'margin-bottom': 0,
                        'vertical-align': 'middle',
                        'min-width' : '16em'
                    }">
      </ngx-skeleton-loader>
      <span *ngIf="propCanBeRemovedFromClass !== undefined">remove property from class</span>
    </button>
  </div>
</mat-menu>
