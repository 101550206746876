<!-- header / toolbar to select view and pagination -->
<div
  *ngIf="!loading && (numberOfAllResults > 0 && resources?.resources?.length) && search.mode === 'gravsearch' && search.projectUuid"
  class="list-view-header">
  <div class="back">
    <button mat-button class="back-button" (click)="handleBackButtonClicked()">
      <span>Go Back to Search Form</span>
      <mat-icon iconPositionEnd>chevron_right</mat-icon>
    </button>
  </div>
</div>
<dasch-swiss-app-pager #pager (pageChanged)="doSearch($event)" [numberOfAllResults]="numberOfAllResults">
</dasch-swiss-app-pager>

<!-- container with search results -->
<div class="list-view-container">
  <dasch-swiss-app-progress-indicator *ngIf="loading"></dasch-swiss-app-progress-indicator>
  <div *ngIf="!loading && (numberOfAllResults > 0 && resources)">
    <app-resource-list
      [withMultipleSelection]="true"
      [resources]="resources"
      [selectedResourceIdx]="selectedResourceIdx"
      (resourcesSelected)="emitSelectedResources($event)">
    </app-resource-list>
  </div>
  <!-- In case of 0 result -->
  <div
    data-cy="list-view-no-results"
    class="list-view no-results"
    *ngIf="!loading && (!(resources?.resources) || resources?.resources?.length === 0)">
    <!-- Case A: user did a fulltext search -->
    <div *ngIf="search.mode === 'fulltext'">
      <p>Your search - <strong> {{search.query}}</strong> - did not match any documents.</p>
      <p>Suggestions:</p>
      <ul>
        <li *ngIf="numberOfAllResults > 0">
          Make sure that you are logged in and you have all the needed permissions.
        </li>
        <li>Make sure that all words are spelled correctly.</li>
        <li>Try different keywords.</li>
        <li>Try more general keywords.</li>
        <li>Try fewer keywords.</li>
      </ul>
      <mat-divider></mat-divider>
      <app-hint [topic]="'search'"></app-hint>
    </div>
    <!-- Case B: user clicked on the class item -->
    <div *ngIf="search.mode !== 'fulltext'">
      <div *ngIf="search.mode === 'gravsearch' && search.projectUuid" class="back">
        <button mat-button class="back-button" (click)="handleBackButtonClicked()">
          <span>Go Back to Search Form</span>
          <mat-icon iconPositionEnd>chevron_right</mat-icon>
        </button>
      </div>
      <!-- Case B1: there is no resources created-->
      <div *ngIf="numberOfAllResults === 0" class="no-results-container">
        <mat-icon>warning</mat-icon>
        <div>No results were found for your query.</div>
      </div>
      <!-- Case B2: no permission-->
      <div *ngIf="numberOfAllResults > 0" class="no-results-container">
        <mat-icon>warning</mat-icon>
        <div>
          It seems like you don’t have the necessary permissions.<br />
          Check with a project admin if you have the necessary permission or if you are logged in.
        </div>
      </div>
    </div>
  </div>
</div>
