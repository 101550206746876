<div class="timeline-wrapper" #timeline [class.disabled]="disabled">
  <div class="progress-wrapper" #progress>
    <div class="progress-background"></div>
    <div class="progress-buffer"></div>
    <div class="progress-fill"></div>
  </div>
  <div
    class="thumb"
    #thumb
    cdkDrag
    cdkDragLockAxis="x"
    [class.dragging]="dragging"
    [cdkDragDisabled]="disabled"
    (cdkDragStarted)="toggleDragging()"
    (cdkDragEnded)="toggleDragging()"
    (cdkDragMoved)="dragAction($event)"
    cdkDragBoundary=".timeline-wrapper"></div>
</div>
